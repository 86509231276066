import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const AtriasLogo: React.FC<SvgIconProps> = (props: SvgIconProps) => (

  <SvgIcon {...props} viewBox="0 0 22 24">

    <path fill="#B12029" d="M22,0v24h-6V6H6v2H0V0H22z M12.8,11.8v5.9H6v6.2H0V11.8H12.8z" />

  </SvgIcon>

);

export default AtriasLogo;
