import { createMuiTheme } from '@material-ui/core/styles';
import './atrias-theme.scss';

// Disabling this line because the font can only be imported using require.
// No unused vars is disabled because the font needs to be loaded.
// eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unused-vars
const OpenSansFont = require('typeface-open-sans');

// Disabling this line because the font can only be imported using require.
// No unused vars is disabled because the font needs to be loaded.
// eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unused-vars
const LatoFont = require('typeface-lato');

const OPEN_SANS = 'Open Sans';
const LATO = 'Lato';
const atriasRed = '#af1f22';
const atriasRedLight = '#ce3135';

const bold = 'bold' as never;
const extraBold = 800;
const light = 'lighter' as never;
const white = '#ffffff';

const primaryColor = {
  light: atriasRedLight,
  main: atriasRed,
  dark: '#991425',
};

const secondaryColor = {
  light: '#d3d3d3',
  main: '#686868',
  dark: '#262626',
  contrastText: white,
};

const typography = {
  h1: {
    fontFamily: OPEN_SANS,
    color: atriasRed,
    fontWeight: bold,
    fontSize: '1.875rem' as never,
  },
  h2: {
    fontFamily: OPEN_SANS,
    color: atriasRed,
    fontWeight: bold,
    fontSize: '1.563rem' as never,
  },
  h3: {
    fontFamily: OPEN_SANS,
    fontWeight: bold,
    fontSize: '1.563rem' as never,
  },
  h4: {
    fontFamily: OPEN_SANS,
    fontWeight: extraBold,
    fontSize: '1.375rem' as never,
  },
  h5: {
    fontFamily: OPEN_SANS,
    fontWeight: bold,
    fontSize: '1.375rem' as never,

  },
  h6: {
    fontFamily: OPEN_SANS,
    fontWeight: bold,
    fontSize: '1.25rem' as never,
  },
  subtitle1: {
    fontFamily: OPEN_SANS,
    fontWeight: light,
    fontSize: '1.25rem',
  },
  subtitle2: {
    fontFamily: OPEN_SANS,
    fontWeight: light,
    fontSize: '1.125rem',
  },
  button: {
    fontFamily: OPEN_SANS,
    fontSize: '1rem',
  },
  body1: {
    fontFamily: LATO,
    fontSize: '1.25rem',
    lineHeight: '30px',
  },
  body2: {
    fontFamily: LATO,
    fontSize: '1rem',
    lineHeight: '22px',
  },
  caption: {
    fontFamily: OPEN_SANS,
    fontWeight: 600,
    fontSize: '0.875rem',
    color: secondaryColor.dark,
  },
  overline: {
    fontFamily: OPEN_SANS,
    fontWeight: bold,
    fontSize: '0.875rem',
    color: secondaryColor.dark,
  },

};

const selectPositionProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom' as never,
    horizontal: 'left' as never,
  },
};

const props = {
  MuiSelect: {
    MenuProps: selectPositionProps as never,
    disableScrollLock: true,
  },
  MuiCheckbox: {

  },
};

const errorColor = {
  main: atriasRedLight,
};

const overrides = {
  MuiInputLabel: {
    root: {
      fontFamily: OPEN_SANS,
      fontSize: '1.125rem',
    },
  },
  MuiCardContent: {
    root: {
      padding: '32px',
    },
  },
  MuiCardActions: {
    root: {
      padding: '32px',
    },
  },
  MuiFormHelperText: {
    root: {
      fontWeight: 'bold' as never,
      fontSize: '0.875rem' as never,
      color: secondaryColor.main,
    },
  },
  MuiSvgIcon: {
    colorPrimary: {
      color: `${primaryColor.main} !important`,
    },
    colorSecondary: {
      color: `${secondaryColor.main} !important`,
    },
  },
  MuiLink: {
    root: {
      textDecoration: 'underline',
      fontWeight: 600,
      fontSize: '1.25rem',
    },
    underlineHover: {
      textDecoration: 'underline',
    },
  },
  MuiSelect: {
    icon: {
      color: atriasRed,
    },
  },
};

const palette = {
  primary: primaryColor,
  secondary: secondaryColor,
  error: errorColor,
};

const AtriasTheme = createMuiTheme({
  typography,
  palette,
  overrides,
  props,
});

export default AtriasTheme;
