import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, Container, makeStyles } from '@material-ui/core';

import styles from './CookieBanner.module.scss';

const useStyles = makeStyles((theme) => ({
  decline__button: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: '32px !important',
    },
  },
}));

interface CookieBannerProps {

  onAccept: () => void;
  onDecline: () => void;
  acceptLabel: string;
  declineLabel: string;
  children: JSX.Element | JSX.Element[]

}

const CookieBanner: React.FC<CookieBannerProps> = (props: CookieBannerProps) => {
  const classes = useStyles();

  const declineClasses = [
    classes.decline__button,
  ].join(' ');

  return (
    <Grid container direction="row" justify="center" className={styles.banner}>
      <Container>
        <Grid item container direction="row" justify="center" alignItems="center" className={styles.banner__container}>
          <Grid item className={styles.banner__text} xs={12} md={8} lg={7}>
            {
                props.children
            }
          </Grid>
          <Grid item container justify="flex-end" xs={12} md={4} lg={5} className={styles.banner__buttons}>
            <Button
              className={declineClasses}
              style={{ padding: '14px 16px' }}
              onClick={props.onDecline}
              color="primary"
            >
              {props.declineLabel}
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={styles.banner__button}
              onClick={props.onAccept}
            >
              {props.acceptLabel}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default CookieBanner;
