import React from 'react';
import { Container, Link, Typography } from '@material-ui/core';
import styles from './EnvironmentBanner.module.scss';

interface EnvironmentBannerProps {
  environment: string;
  version: string;
  explanationText: string;
  url?: string;
  urlText?: string;
}

const EnvironmentBanner: React.FC<EnvironmentBannerProps> = (props: EnvironmentBannerProps) => {
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
  return (
    <div className={styles['outer-container']}>
      <Container>
        <div className={styles['inner-container']}>
          <Typography variant="body1" color="primary" className={styles.environment}>
            {props.environment}
          </Typography>
          <Typography variant="body1" color="primary" className={styles.version}>
            {props.version}
          </Typography>
          <span className={styles.spacer} />
          <Typography>
            {props.explanationText}
          </Typography>
          {props.url ? (
            <Typography>
              <Link className={styles['atrias-link']} onClick={preventDefault} href={props.url}>{props.urlText}</Link>
            </Typography>
          ) : null}

        </div>

      </Container>
    </div>
  );
};

EnvironmentBanner.defaultProps = {
  url: undefined,
  urlText: undefined,
};

export default EnvironmentBanner;
