/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Grid } from '@material-ui/core';
import AtriasLogo from '../../icons/AtriasLogo';
import styles from './PageLoader.module.scss';

interface PageLoaderProps {
  image?: string;
}

const PageLoader: React.FC<PageLoaderProps> = (props: PageLoaderProps) => (
  <Grid className={styles['loading-container']} container justify="center" alignItems="center">
    {
            props.image ? (<img className={styles['logo-loading']} src={props.image} alt="Loading" />) : (
              <span className={styles['logo-loading']}><AtriasLogo style={{ fontSize: 100 }} /></span>)
      }

  </Grid>
);

PageLoader.defaultProps = {
  image: undefined,
};

export default PageLoader;
